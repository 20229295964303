import React, { useState, useEffect } from "react";
import "../css/Slider.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";

const Slider = () => {
  const [slider, setSlider] = useState([]);

  const fetchSlider = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agrouls_backend/slider.php`
      );
      setSlider(response.data);
    } catch (error) {
      console.error("Error fetching slider data:", error);
    }
  };

  useEffect(() => {
    fetchSlider();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Slider">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        responsive={responsive}>
        {slider.map((slide, index) => (
          <div key={index} className="slide-item">
            <h1>{slide.titulo}</h1>
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${slide.slider}`}
              alt="Slide 1"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
