import React, { useRef, useState } from "react";
import "../css/Panel_Contacto.css";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";

function Panel_Contacto() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm("service_anb26ig", "template_dj43bhh", form.current, {
        publicKey: "G_7XH66lpB_aj_PTt",
      })
      .then(
        () => {
          e.target.reset();
          toast.success("Solicitud envíada con éxito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          recaptchaRef.current.reset();
        },
        (error) => {
          toast.error("Hubo un error en el envío", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          recaptchaRef.current.reset();
        }
      );
  };
  return (
    <div className="ContenedorContacto">
      <h1>Contacto</h1>
      <form ref={form} className="contenedorFormulario" onSubmit={sendEmail}>
        <div className="contenedorNombreApellidoEmail">
          <div className="contenedorNombreApellido">
            <div className="contenedorNombre">
              <label>NOMBRE</label>
              <input
                placeholder="Nombre"
                type="text"
                name="user_name"
                required></input>
            </div>
            <div className="contenedorApellido">
              <label>APELLIDO</label>
              <input
                placeholder="Apellido"
                type="text"
                name="user_last_name"
                required></input>
            </div>
          </div>
          <div className="contenedorEmail">
            <label>EMAIL</label>
            <input
              placeholder="Email"
              type="email"
              name="user_email"
              required></input>
          </div>
        </div>
        <div className="contenedorComentario">
          <label>COMENTARIO</label>
          <textarea name="message" required></textarea>
        </div>
        <div className="captcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcHAYYqAAAAAIflJTWPnmvmc3RZSIEn8q16OHNL"
          />
        </div>
        <button className="botonEnviar">ENVIAR</button>
        {loading && <p className="textoCargando">Cargando...</p>}
      </form>
      <ToastContainer />
    </div>
  );
}

export default Panel_Contacto;
