import React, { useState, useEffect } from "react";
import "../css/Panel_CEC.css";
import axios from "axios";

function Panel_CEC() {
  const [cec, setCEC] = useState([]);
  const [integrantes, setIntegrantes] = useState([]);
  const [archivo, setArchivo] = useState([]);

  const fetchCEC = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cec_1.php`
    );
    setCEC(response.data);
  };

  const fetchArchivo = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cec_2.php`
    );
    setArchivo(response.data);
  };

  const fetchIntegrantes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cec_3.php`
    );
    setIntegrantes(response.data);
  };

  const handleDownload = (fileName) => {
    // URL del archivo en el servidor (puede ser una imagen, PDF, etc.)
    const fileUrl = `${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${fileName}`;
    // Crear un enlace temporal para forzar la descarga
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName || "archivo-descargado"; // Nombre predeterminado del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Eliminar el enlace una vez utilizado
  };

  useEffect(() => {
    fetchCEC();
    fetchArchivo();
    fetchIntegrantes();
  }, []);

  return (
    <div className="ContenedorCEC">
      <h1>Líneas trabajo CEC</h1>
      {cec.map((cec, index) => (
        <div key={index}>
          <p>{cec.presentacion}</p>
          <div className="contenedorDirectorioPrograma">
            <div>
              <h4>Directorio</h4>
              <p>{cec.directorio}</p>
            </div>
            <div>
              <h4>Programa de trabajo</h4>
              <p>{cec.programa}</p>
              <div className="planificacion">
                {archivo.map((file, index) => (
                  <div key={index}>
                    <button onClick={() => handleDownload(file.archivo)}>
                      DESCARGA
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="miembrosCEC">
        {integrantes.map((integrante, index) => (
          <div key={index}>
            <div className="imgCEC">
              <img
                src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${integrante.imagen}`}></img>
            </div>
            <p>{integrante.nombre}</p>
            <p>{integrante.correo}</p>
            <p>{integrante.cargo}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_CEC;
