import React, { useState, useEffect } from "react";
import "../css/Panel_Perfil.css";
import axios from "axios";

function Panel_Perfil() {
  const [capacidad, setCapacidad] = useState([]);
  const [archivo, setArchivo] = useState([]);

  const fetchCapacidad = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/perfil_1.php`
    );
    setCapacidad(response.data);
  };

  const fetchArchivo = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/perfil_2.php`
    );
    setArchivo(response.data);
  };

  const handleDownload = (fileName) => {
    // URL del archivo en el servidor (puede ser una imagen, PDF, etc.)
    const fileUrl = `${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${fileName}`;
    // Crear un enlace temporal para forzar la descarga
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName || "archivo-descargado"; // Nombre predeterminado del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Eliminar el enlace una vez utilizado
  };

  useEffect(() => {
    fetchCapacidad();
    fetchArchivo();
  }, []);

  return (
    <div className="ContenedorPerfil">
      <h3>
        El Ingeniero Agrónomo de la Universidad de La Serena es un profesional
        que:
      </h3>
      <ol>
        {capacidad.map((cap, index) => (
          <div key={index}>
            <li>{cap.capacidades}</li>
            <br />
          </div>
        ))}
      </ol>
      <div className="planEstudios">
        {archivo.map((file, index) => (
          <div key={index}>
            <button onClick={() => handleDownload(file.archivo)}>
              PLAN DE ESTUDIOS
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_Perfil;
