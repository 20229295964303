import React, { useState, useEffect } from "react";
import "../css/Panel_Descargas.css";
import axios from "axios";

function Panel_Descargas() {
  const [descargas, setDescargas] = useState([]);

  const fetchDescargas = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/descargas.php`
    );
    setDescargas(response.data);
  };

  const handleDownload = (fileName) => {
    // URL del archivo en el servidor (puede ser una imagen, PDF, etc.)
    const fileUrl = `${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${fileName}`;
    // Crear un enlace temporal para forzar la descarga
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName || "archivo-descargado"; // Nombre predeterminado del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Eliminar el enlace una vez utilizado
  };

  useEffect(() => {
    fetchDescargas();
  }, []);

  return (
    <div className="ContenedorDescargas">
      <h1>Descargas</h1>
      <p>Para ver la revista en línea, debes pulsar sobre la portada.</p>
      <div className="contenedorArchivos">
        {descargas.map((descarga, index) => (
          <div key={index}>
            <p>{descarga.titulo}</p>
            <p
              className="linkDescarga"
              onClick={() => handleDownload(descarga.archivo)}>
              Descargar revista N° {descarga.orden * 1 + 1}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_Descargas;
