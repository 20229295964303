import React, { useState, useEffect } from "react";
import "../css/Panel_Documentos.css";
import icon from "../images/book-64.ico";
import axios from "axios";

function Panel_Documentos() {
  const [documentos, setDocumentos] = useState([]);

  const fetchDocumentos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/documentos.php`
    );
    setDocumentos(response.data);
  };

  const handleDownload = (fileName) => {
    // URL del archivo en el servidor (puede ser una imagen, PDF, etc.)
    const fileUrl = `${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${fileName}`;
    // Crear un enlace temporal para forzar la descarga
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName || "archivo-descargado"; // Nombre predeterminado del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Eliminar el enlace una vez utilizado
  };

  useEffect(() => {
    fetchDocumentos();
  }, []);

  return (
    <div className="ContenedorDocumentos">
      <h1>Documentos</h1>
      {documentos.map((documento, index) => (
        <div key={index} className="documento">
          <div className="logoDocumento">
            <img src={icon} />
          </div>
          <div className="textoDocumento">
            <h4>{documento.titulo}</h4>
            <a onClick={() => handleDownload(documento.archivo)}>Descargar</a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Panel_Documentos;
