import React, { useState, useEffect } from "react";
import "../css/Panel_Cuerpo.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import no_image from "../images/no_image.jfif";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Panel_Cuerpo() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [academicoSeleccionado, setAcademicoSeleccionado] = useState(null);

  const [cuerpo, setCuerpo] = useState([]);

  const fetchCuerpo = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cuerpo.php`
    );
    setCuerpo(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(modalIsOpen);
    fetchCuerpo();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [modalIsOpen]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleOpenModal = (academico) => {
    setAcademicoSeleccionado(academico);
    setIsOpen(true);
  };

  return (
    <div className="ContendorCuerpo">
      <h1>Cuerpo Académico</h1>
      <Tabs>
        <TabList>
          <Tab>Dpto. Agronomía</Tab>
          <Tab>Dpto. Artes y Letras</Tab>
          <Tab>Dpto. Biología</Tab>
          <Tab>Dpto. Física</Tab>
          <Tab>Dpto. Ingeniería de Minas</Tab>
          <Tab>Dpto. Matemáticas</Tab>
          <Tab>Dpto. Química</Tab>
        </TabList>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Agronomía")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Artes y Letras")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Biología")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Física")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Ingeniería de Minas")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Matemáticas")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Química")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
      </Tabs>
      <CSSTransition
        in={modalIsOpen}
        timeout={300}
        classNames="modal-transition"
        unmountOnExit>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <button className="close" onClick={() => setIsOpen(false)}>
            ✖
          </button>
          <div className="contenedorImagenModal">
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${academicoSeleccionado?.imagen}`}
              onError={(e) => (e.target.src = no_image)}></img>
          </div>
          <div className="contenedorTextoModal">
            <h4>{academicoSeleccionado?.nombre}</h4>
            <p>{academicoSeleccionado?.titulo}</p>
            <p className="correoAcademico">{academicoSeleccionado?.correo}</p>
            <p className="especialidad">{academicoSeleccionado?.descripcion}</p>
          </div>
        </Modal>
      </CSSTransition>
    </div>
  );
}
export default Panel_Cuerpo;
